.login-container {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(240, 242, 245);
    overflow: hidden;
}

.login-child {
    width: 400px;
    padding: 24px;
    background: white;
    border-radius: 2px;
}