.image-wrapper {
    background-color: #f0f2f5;
    border-radius: 2px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.result-wrapper {
  background-color: #f0f2f5;
  border-radius: 2px;
  padding: 8px;
  width: 100% !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.ant-skeleton {
width: 100% !important;
}

  .ant-skeleton-image {
    width: 100% !important;
  }