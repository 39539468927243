
.site-layout-pageheader {
  margin: 16px !important;
  margin-bottom: 0px !important;
}

.ant-page-header-heading {
  align-items: center;
}

.ant-page-header-heading-left {
  align-items: baseline !important;
}

.ant-page-header-heading-left .ant-page-header-back {
  align-self: center;
  margin-right: 24px;
}

.ant-page-header-heading-left .ant-page-header-heading-title {
  font-size: 24px;
}

.ant-page-header-content {
  padding-top: 0px !important;
}

.description {
  position: relative;
  margin-top: 8px;
  filter: opacity(1);
  overflow: hidden;
  transition: all 0.5s;
}

  .description.collapsed {
    max-height: 0px !important;
    margin-top: 0px;
    filter: opacity(0);
  }

.collapse-buttom {
  padding-left: 0px !important;
  margin-top: 8px;
  transition: all 0.5s;
}

  .collapse-buttom.collapsed {
    margin-top: 0px;
  }

.ant-back-top {
  right: 60px !important;
}

  .ant-back-top .backtop {
    background-color: white !important;
    box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%);
  }
