body {
  margin: 0;
}

#root {
  height: 100%;
}

/* width */
::-webkit-scrollbar {
  width: 14px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: transparent; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.45);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #555; 
}