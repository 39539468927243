.header-icon {
    font-size: 16px;
    cursor: pointer;
    transition: color 0.3s;
  }
  
  .header-icon:hover {
    color: #1890ff;
  }

  .header-icon.trigger {
    font-size: 20px;
    padding: 0 24px;
  }
  
.logo {
  height: 32px;
  margin: 14px;
  background: rgba(255, 255, 255, 0.3);
}

.ant-menu-submenu-selected {
  background-color: #1890ff !important;
}

.fast-out .ant-menu-item,
.fast-out .ant-menu-submenu-title {
  transition: border-color 0.3s, background 0.3s, padding 0.3s cubic-bezier(0.075, 0.82, 0.165, 1) !important;
}

.site-layout {
	height: 100vh;
	overflow: hidden;
}

	.site-layout .site-layout-background {
		background: #fff;
	}
  
	.site-layout .ant-layout-header {
		margin: 0px;
		padding: 0px;
		box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%) !important;
	}

	.site-layout .ant-layout-content {
		overflow-y: auto;
		overflow-x: hidden;
		margin: 0px 16px 16px 16px;
		padding: 24px;
	}